.camera-button {
	position: absolute;
	margin-top: -50px;
    margin-left: 100px;

    i {
    	font-size: 24pt;
    }
}

.iamge-capture {
	width: 150px;
	height: 150px;
}

.video-capture {
	object-fit: cover;
	border-radius: 100%;
	width: 150px;
	height: 150px;
}