.image-glasspane {
	position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    z-index: 1000;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: rgba(0,0,0,0.8);

    &.image-glasspane-hide {
	    display: none;
	}
}